<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    color="grey darken-3"
    width="260"
    v-bind="$attrs"
  >
    <v-list nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="../../assets/logo.png" max-height="40" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1 white--text"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />
    <v-list nav>
      <v-list-item :to="timeReport.to" :active-class="`primary white--text`">
        <v-list-item-icon>
          <v-icon color="white" v-text="timeReport.icon" />
        </v-list-item-icon>

        <v-list-item-title class="white--text" v-text="timeReport.title" />
      </v-list-item>
      <template v-if="user.rule == 'admin'">
        <v-list-group
          v-for="item in admin"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          class="whiteList whiteIcon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
            class="whiteList"
            :active-class="`primary white--text`"
          >
            <v-list-item-icon class="whiteIcon" v-if="child.icon">
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn color="primary" block @click="signOut">
          Sign out
          <v-icon right dark> mdi-logout </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    timeReport: {
      icon: "mdi-calendar-clock",
      title: "Time report",
      to: "/time-report",
    },
    admin: [
      {
        icon: "mdi-tune",
        title: "Admin",
        items: [
          {
            icon: "mdi-domain",
            title: "Project",
            to: "/admin/projects",
          },
          {
            icon: "mdi-account",
            title: "Users",
            to: "/admin/users",
          },
          {
            icon: "mdi-account-clock",
            title: "Time",
            to: "/admin/time",
          },
          {
            icon: "mdi-view-dashboard",
            title: "Report",
            to: "/admin/report",
          },
        ],
      },
    ],

    profile: {
      title: "Zool",
      subTitle: "Time",
    },
  }),

  computed: {
    ...mapGetters({
      user: "users/user",
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("users/userSignOut");
    },
  },
};
</script>
<style lang="scss" scooped>
.whiteList
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
}

.whiteIcon .theme--light.v-icon {
  color: white;
}
</style>